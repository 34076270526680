var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-10",attrs:{"fluid":""}},[_c('snack-bar',{attrs:{"content":_vm.snackbarSuccess},model:{value:(_vm.isCreateSuccess),callback:function ($$v) {_vm.isCreateSuccess=$$v},expression:"isCreateSuccess"}}),_c('snack-bar',{attrs:{"content":_vm.snackbarFail,"type":"error"},model:{value:(_vm.isCreateFail),callback:function ($$v) {_vm.isCreateFail=$$v},expression:"isCreateFail"}}),_c('preview',{attrs:{"row-item":_vm.rowItem,"qr-code-options":_vm.qrCodeOptions,"show":_vm.isPreview,"close-preview":_vm.closePreview}}),_c('v-row',[_c('v-col',[_c('v-breadcrumbs',{staticClass:"px-0",attrs:{"items":_vm.breadcrumbs,"divider":">"}}),_c('div',{staticClass:"d-flex justify-space-between"},[_c('h3',{staticClass:"pageHeadline",attrs:{"t-data":"page-headline"}},[_vm._v(" QR Code Management ")])])],1)],1),_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","t-data":"search-panel"}},[_c('search',{attrs:{"search":_vm.search,"label":"Search by Branch, Initials"},on:{"input":_vm.textSearch,"clear":function($event){return _vm.clearSearch()}}})],1)],1)],1),_c('v-layout',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1 custom-table localize-table",staticStyle:{"width":"100%"},attrs:{"t-data":"data-table","headers":_vm.headers,"items":_vm.qrCodeList,"loading":_vm.isLoading,"options":_vm.options,"items-per-page":_vm.pageSize,"server-items-length":_vm.total,"footer-props":{'items-per-page-options': _vm.itemsPerPage},"item-key":"id","sort-desc":true,"must-sort":false,"fixed-header":"","height":_vm.windowSize.y - _vm.heightOptions.header - _vm.heightOptions.tableFooter},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.org.name))]}},{key:"item.org_codename",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.org_codename.toUpperCase()))]}},{key:"item.update_date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.dateFmt(item.update_date)))]}},{key:"item.update_by_user",fn:function(ref){
var item = ref.item;
return [_c('username-status-text',{attrs:{"user":item.update_by_user}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"px-2 action-icon",attrs:{"t-data":"preview-action"},on:{"click":function($event){return _vm.showPreview(item)}}},[_vm._v("mdi-qrcode")]),_c('v-icon',{staticClass:"px-2 action-icon",attrs:{"t-data":"edit-action"},on:{"click":function($event){return _vm.rowClick(item.id)}}},[_vm._v("mdi-pencil-outline")])]}}])})],1),_c('div',{staticClass:"table-footer-prepend",attrs:{"t-data":"total-result-content"}},[_c('div',{staticClass:"vertical-center pl-4"},[_c('b',[_vm._v(_vm._s(_vm.total))]),_vm._v(" Search results ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container class="px-10" fluid>
    <snack-bar v-model="isCreateSuccess" :content="snackbarSuccess"></snack-bar>
    <snack-bar
      v-model="isCreateFail"
      :content="snackbarFail"
      type="error"
    ></snack-bar>
    <preview
      :row-item="rowItem"
      :qr-code-options="qrCodeOptions"
      :show="isPreview"
      :close-preview="closePreview"
    />
    <!-- Breadcrumbs -->
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <div class="d-flex justify-space-between">
          <h3 class="pageHeadline" t-data="page-headline">
            QR Code Management
          </h3>
        </div>
      </v-col>
    </v-row>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" t-data="search-panel">
            <search
              :search="search"
              label="Search by Branch, Initials"
              @input="textSearch"
              @clear="clearSearch()"
            ></search>
          </v-col>
        </v-row>
      </v-card-title>
      <v-layout fluid v-resize="onResize">
        <v-data-table
          t-data="data-table"
          :headers="headers"
          :items="qrCodeList"
          :loading="isLoading"
          :options.sync="options"
          :items-per-page="pageSize"
          :server-items-length="total"
          :footer-props="{'items-per-page-options': itemsPerPage}"
          item-key="id"
          :sort-desc="true"
          :must-sort="false"
          class="elevation-1 custom-table localize-table"
          fixed-header
          style="width: 100%"
          :height="
            windowSize.y - heightOptions.header - heightOptions.tableFooter
          "
        >
          <template v-slot:item.name="{item}">{{ item.org.name }}</template>
          <template v-slot:item.org_codename="{item}">{{
            item.org_codename.toUpperCase()
          }}</template>
          <template v-slot:item.update_date="{item}">{{
            dateFmt(item.update_date)
          }}</template>
          <template v-slot:item.update_by_user="{item}">
            <username-status-text :user="item.update_by_user" />
          </template>
          <template v-slot:item.action="{item}">
            <v-icon
              t-data="preview-action"
              class="px-2 action-icon"
              @click="showPreview(item)"
              >mdi-qrcode</v-icon
            >
            <v-icon
              t-data="edit-action"
              class="px-2 action-icon"
              @click="rowClick(item.id)"
              >mdi-pencil-outline</v-icon
            >
          </template>
        </v-data-table>
      </v-layout>
      <div class="table-footer-prepend" t-data="total-result-content">
        <div class="vertical-center pl-4">
          <b>{{ total }}</b> Search results
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {
  convertDate,
  searchHandler,
  snackbarSuccessContent,
  snackbarFailContent
} from '../../helper/helper'
import Search from '@/components/Search'
import SnackBar from '@/components/SnackBar'
import UsernameStatusText from '@/components/UsernameStatusText'
import Preview from '@/components/qr-code/Preview'

export default {
  components: {
    SnackBar,
    Search,
    UsernameStatusText,
    Preview
  },
  data() {
    return {
      pageName: 'QR Code',
      heightOptions: {
        header: 312,
        tableFooter: 59
      },
      windowSize: {
        x: 0,
        y: 0
      },
      isCreateSuccess: false,
      isCreateFail: false,
      isPreview: false,
      snackTitle: '',
      breadcrumbs: [
        {
          text: 'Assistant Tool',
          exact: true,
          href: '/'
        },
        {
          text: 'QR Code Management',
          disabled: true,
          link: true,
          href: '/'
        }
      ],
      search: '',
      options: {},
      page: 1,
      headers: [
        {
          text: 'Branch',
          align: 'left',
          value: 'name',
          sortable: true,
          width: 382
        },
        {
          text: 'Initials',
          align: 'left',
          value: 'org_codename',
          sortable: true,
          width: 182
        },
        {
          text: 'Updated Date/Time',
          align: 'center',
          value: 'update_date',
          sortable: false,
          width: 212
        },
        {
          text: 'Updated by',
          align: 'left',
          value: 'update_by_user',
          sortable: false,
          width: 212
        },
        {
          text: 'Actions',
          align: 'center',
          value: 'action',
          sortable: false,
          width: 92
        }
      ],
      pageSize: 25,
      itemsPerPage: [25, 50, 100],
      rowItem: {
        org: {
          name: ''
        },
        org_codename: ''
      },
      qrCodeOptions: {
        width: 300,
        height: 300,
        data: '',
        margin: 10,
        qrOptions: {
          typeNumber: 0,
          mode: 'Byte',
          errorCorrectionLevel: 'Q'
        },
        imageOptions: {
          hideBackgroundDots: true,
          imageSize: 0.4,
          margin: 0,
          crossOrigin: 'anonymous'
        },
        dotsOptions: {
          color: '#000000'
        },
        backgroundOptions: {
          color: '#ffffff'
        },
        cornersSquareOptions: {
          color: '#000000'
        },
        cornersDotOptions: {
          color: '#000000'
        }
      }
    }
  },
  computed: {
    snackbarSuccess() {
      return snackbarSuccessContent(this.pageName, this.snackTitle)
    },
    snackbarFail() {
      return snackbarFailContent(this.pageName, this.snackTitle)
    },
    ...mapState('qrCode', ['isLoading', 'qrCodeList', 'total'])
  },
  async created() {
    await this.fetch()
  },
  beforeRouteLeave(to, from, next) {
    this.setTextSearch('')
    this.resetList()
    next()
  },
  mounted() {
    let query = this.$route.query.isCreateSuccess
    let querySnackTitle = this.$route.query.snackTitle

    if (query) {
      this.$router.replace({})
      this.isCreateSuccess = query
      this.snackTitle = querySnackTitle
    }
  },
  methods: {
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight}
    },
    showPreview(item) {
      this.rowItem = item
      this.qrCodeOptions.data = item.validation_code
      this.isPreview = true
    },
    closePreview() {
      this.isPreview = false
    },
    dateFmt: date => convertDate(date),
    textSearch(data) {
      this.search = data
      this.timer = searchHandler(this.timer, clearTimeout, async () => {
        this.setTextSearch(this.search.trim())
        this.options.page = 1
        await this.fetch()
      })
    },
    clearSearch() {
      this.setTextSearch('')
      this.options.page = 1
      this.fetch()
    },
    rowClick(id) {
      this.$router.push({name: 'qr-code-edit', params: {id: id}})
    },
    ...mapActions('qrCode', [
      'fetch',
      'resetList',
      'setFieldSort',
      'setPage',
      'setPageSize',
      'setSort',
      'setTextSearch'
    ])
  },
  watch: {
    options: {
      handler() {
        const {sortBy, sortDesc, page, itemsPerPage} = this.options
        this.setPage(page)
        this.setFieldSort(sortBy[0])
        this.setPageSize(itemsPerPage)
        this.setSort(sortDesc[0])
        this.fetch()
      },
      deep: true
    }
  }
}
</script>

<template>
  <quick-preview
    :is-preview="show"
    :show-close-btn="true"
    :show-tab="false"
    @input="closePreview"
  >
    <template v-slot:content-th>
      <qr-code-styling
        :row-item="rowItem"
        :options="qrCodeOptions"
        class="pb-4"
      />
    </template>
  </quick-preview>
</template>
<script>
import QuickPreview from '@/components/QuickPreview'
import QrCodeStyling from '@/components/QrCodeStyling'

export default {
  name: 'Preview',
  components: {
    QrCodeStyling,
    QuickPreview
  },
  props: {
    show: {type: Boolean, required: true},
    closePreview: {type: Function},
    rowItem: {type: Object, required: true},
    qrCodeOptions: {type: Object, required: true}
  }
}
</script>